.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.jKVoct{
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-dropdown-select-content{
  height: 20px;
  font-size: 14px;
  font-family: 'Poppins';
  width: 90%;
}
.react-dropdown-select-content span{
  white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.react-dropdown-select-input{
  font-family: 'Poppins' !important;
  font-size: 14px !important;
}

.MuiInputBase-input{
  font-size: 14px !important;
}
.react-dropdown-select-dropdown span{
  font-size: 14px;
  font-family: 'Poppins';
}
.searchWrapper{
  border: none;
}
.MuiMenu-paper{
  box-shadow: none !important;
  border: 1px #ccc solid;
  border-radius: 10px !important;
}
.MuiMenu-list li{
font-size: 14px;
font-family: 'Poppins';
padding: 0;
}
.MuiMenu-list li a{
  text-decoration: none;
  color:#000;
  display: block;
  padding: 6px 16px;
  width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
